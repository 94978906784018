<template>
  <div class="mr-5 ml-4">
        <v-card tile flat v-if="loading" height="40">
          <div class="text-center">
            Loading
          <v-progress-circular indeterminate color="primary" :width="3" :size="32"></v-progress-circular>
          </div>
        </v-card>
        <v-card flat tile v-if="!loading && applicants === null">
          No data found
        </v-card>
        <v-data-table
          :headers="headers"
          :items="applicants"
          hide-default-footer
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          @click:row="showSingleApplicant"
          v-if="!loading && applicants !== null"
          :loading="loading"
          >
          <template v-slot:item.username="{item}" >
            <span class="text-capitalize"> {{ item.username }} </span>
          </template>
           <template v-slot:item.role="{item}" >
            <span class="text-capitalize"> {{ item.role }} </span>
          </template>
          <template v-slot:item.created_at="{item}" >
            <span> {{ formatUTCTime(item.created_at) }} </span>
          </template>
          <template v-slot:item.actions >
            <v-btn text color="primary"> 
              <v-icon
                small
                class="mr-2"
              >
                mdi-pencil
              </v-icon>
              Edit role
             </v-btn>
          </template>
        </v-data-table>
        <div class="d-flex verify-pagination pt-5" v-if="applicants !== null && applicants.length !== 0">
          <v-pagination
            v-model="page"
            :length="lastPage"
            :total-visible="5"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            next-aria-label="mdi-chevron-double-left"
            previous-aria-label="mdi-chevron-double-right"
          ></v-pagination>
          <v-spacer></v-spacer>
          <span v-if="applicants !== null && applicants.length !== 0" class="mt-2 mr-8 pagination-color">{{ page }} - {{ applicants.length }} out of {{total }} items</span>
        </div>
      <ChangeRoleModal :showDialog="show" :selectedUser="selectedUser" @cancelDialog="handleClose" @edited="handleEdited" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import timezoneMxn from '@/mixins/timeZoneMxn';

export default {
  name: "userManagement",
  components: {
    ChangeRoleModal: () => import('@/modules/userManagement/changeRoleModal')
  },
  mixins: [timezoneMxn],
  data() {
    return {
      loading: false,
      pagination: null,
      userBusinessUnits: [],
      page: 1,
      itemsPerPage: 10,
      total: 10,
      lastPage: 1,
      length: 3,
      search: false,
      headers: [
        { text: 'Username', value: 'username', class: 'header' },
        { text: 'Email', value: 'email', class: 'header' },
        { text: 'Role', value: 'role', class: 'header' },
        { text: 'Date', value: 'created_at', class: 'header' },
        { text: 'Actions', value: 'actions', class: 'header' },
      ],
      applicants: null,
      selectedUser: null,
      show: false,
    }
  },
  watch: {
    async page() {
      this.applicants = null;
      this.applicants = await this.retrieveUsers();
    },
  },
  mounted() {
    this.retrieveUsers();
  },
  methods: {
    ...mapActions(['performGetActions']),
    showSingleApplicant(val) {
      console.log(val);
      this.selectedUser = val;
      this.show = true;
    },
    handleClose(val) {
      this.show = val;
    },
    handleEdited(val) {
      if (val) {
        this.retrieveUsers();
      }
    },
    async retrieveUsers() {
      this.loading = true;
      const payload = {
        page: this.page
      }

      const fullPayload = {
        params: payload,
        endpoint: '/users',
      }

      const response = await this.performGetActions(fullPayload)
      this.applicants = response.data;
      this.total = response.total;
      this.lastPage = response.lastPage;
      this.perPage = response.perPage;
      this.loading = false;

    }
  }
}
</script>

<style>
.v-pagination__item {
  font-size: 12px;
  margin: .1rem;
  min-width: 19px;
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
.theme--light.v-pagination .v-pagination__item--active {
  color: #1976d2 !important;
  background: #FFFFFF !important;
}
.pagination-color {
 color:#909399;
}
.view_detail_link {
  color: #324BA8;
  font-weight: 700;
  cursor: pointer;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  height: 60px;
  line-height: 16px;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.verify-pagination {
  border-top: 1px solid #e0e0e0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.v-pagination__item {
   font-weight: 600;
   font-size: 13px !important;
}
</style>