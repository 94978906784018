import moment from 'moment';

const timezoneMxn = {
  methods: {
    convertToLocalTime(date) {
      const local = moment.utc(date).local().format();
      return local;
    },
    formatUTCTime(time) {
      const localTime = this.convertToLocalTime(time);
      const formated = moment(localTime).format('Do MMM YYYY');
      return formated;
    },
    checkTime(date) {
      const currentTime = moment();
      const timeStore = moment(date);

      return currentTime.diff(timeStore, 'h');
    },

    determineDuration(val) {
      const localDate = this.convertToLocalTime(val);
      const duration = this.checkTime(localDate);

      // const displayDate = moment(localDate).fromNow();
      const formattedDate = duration <= 24 ? moment(localDate).fromNow() : `${moment(localDate).format('Do MMM')}`;

      return formattedDate;
    },
  },
};

export default timezoneMxn;
